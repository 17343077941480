import React from "react";
import PropTypes from "prop-types";
import { LoadingButton } from "components/ui";
import { Menu, MenuItem, InputBase, Divider, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/MoreVert";
import Send from "@mui/icons-material/Send";
import useTranslate from "hooks/use-translate-language";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0px",
    display: "flex",
    alignItems: "center",
    margin: "1px 2px",
    width: "100%"
  },
  input: {
    marginLeft: 1,
    marginBottom: 0,
    flex: 1
  },
  divider: {
    margin: 2
  }
}));

const SendMsgInput = ({ sendMessage, leaveTwilioChat }) => {
  const translate = useTranslate();
  const [inputValue, setInputValue] = React.useState("");
  const [isSending, setIsSending] = React.useState(false);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <form
      className="send-msg-container"
      onSubmit={async event => {
        event.preventDefault();
        try {
          setIsSending(true);
          await sendMessage(inputValue);
          setInputValue("");
        } finally {
          setIsSending(false);
        }
      }}
      style={{ margin: "0", border: "1px solid #ccc" }}
    >
      {leaveTwilioChat && (
        <>
          <IconButton aria-label="menu" onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            id={`chat-window-menu`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                if (window.confirm(translate("endChatConfirmationMessage"))) {
                  leaveTwilioChat();
                  handleClose();
                } else {
                  handleClose();
                }
              }}
            >
              {translate("leaveChat")}
            </MenuItem>
          </Menu>
          <Divider className={classes.divider} orientation="vertical" />
        </>
      )}
      <InputBase
        className={classes.input}
        placeholder={translate("messagePlaceholder")}
        inputProps={{ "aria-label": "Send Chat Message" }}
        onChange={({ target }) => setInputValue(target.value)}
        value={inputValue}
      />
      <LoadingButton
        color="primary"
        type="submit"
        isFetching={isSending}
        disabled={!inputValue}
      >
        <Send />
      </LoadingButton>
    </form>
  );
};

export default SendMsgInput;

SendMsgInput.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  leaveTwilioChat: PropTypes.func
};
